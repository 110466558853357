export function filterByValue<T>(array: T[], text: string) {
  text = text.toLowerCase()
  return array.filter(
    (o) =>
      text.length == 0 ||
      //@ts-ignore
      Object.values(o)
        .filter((v) => typeof v !== 'object')
         //@ts-ignore
        .map((v) => v.toString().toLowerCase())
        .filter((v) => v.includes(text)).length > 0
  )
}

export function onlyUnique<T>(value: T, index: number, self: T[]) {
  return self.indexOf(value) === index
}

export function toMap<T>(list: T[], keyGetter: (item: T) => any): Map<any, T[]> {
  const map = new Map()
  list.forEach((item) => {
    const key = keyGetter(item)
    const collection = map.get(key)
    if (!collection) {
      map.set(key, [item])
    } else {
      collection.push(item)
    }
  })
  return map
}
