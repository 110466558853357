import { COLOR_PATIENTS, COLOR_PRIMARY } from '../../routes/color-constants'
import { AppTable, Field } from '../../components/table'
import React, { useEffect, useState } from 'react'
import { Actions, Pager, Search, SearchValue, Sort } from '../../components/table/types'
import editIcon from '../../assets/table-icons/edit-icon.svg'
import deleteIcon from '../../assets/table-icons/delete-icon.svg'
import { useTranslation } from 'react-i18next'
import { PATIENT_SERVICE_KEY } from '../../modules/patients'
import { PatientService } from '../../modules/patients/services/PatientService'
import { getPatientContainer } from '../../container/patient-module'
import { Query, QueryParam, QueryParamN, SortParam } from '../../common/api/Query'
import { Patient, PatientQuery } from '../../modules/patients/models/Patient'
import { navigate } from '@reach/router'
import { URL_PATIENT, URL_PATIENT_SHOW } from '../../routes/routes-constants'
import { userGenders } from '../../modules/users/enums/GenderType'
import seeIcon from '../../assets/table-icons/see-icon.svg'
import { Box, Button } from '@material-ui/core'
import { useStyles } from '../matchings/Matching.styles'
import { getAuthContainer } from '../../container/auth-modules'
import { AuthService } from '../../modules/auth/services/AuthService'
import { AUTH_SERVICE_KEY } from '../../modules/auth'
import { Permission } from '../../common/enums/Permissions'

const patientService = getPatientContainer().get<PatientService>(PATIENT_SERVICE_KEY)
const authService = getAuthContainer().get<AuthService>(AUTH_SERVICE_KEY)

const searcherQuery = (
  svs: SearchValue<PatientQuery>[],
): QueryParam<PatientQuery>[] | QueryParamN<PatientQuery>[] =>
  svs.filter((sv) => sv.value).map((sv) => ({ name: sv.name, value: sv.value as string }))

export const Table = () => {
  const { t } = useTranslation()
  const classes = useStyles({ color: COLOR_PRIMARY })

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [pager, setPager] = useState<Pager>()
  const [page, setPage] = useState<number>(0)
  const [count, setCount] = useState<number>(0)
  const [itemsPerPage, setItemsPerPage] = useState<number>(10)
  const [items, setItems] = useState<Patient[]>([])
  const [sort, setSort] = useState<SortParam<Patient>>({
    field: 'firstName',
    desc: true,
  })
  const [searcher, setSearcher] = useState<SearchValue<PatientQuery>[]>([
    {
      name: 'sampleName',
      label: t('search') + ' ' + t('bySampleName'),
    },
    {
      name: 'idPatient',
      label: t('search') + ' ' + t('idPatient'),
    },

    {
      name: 'firstName',
      label: t('search') + ' ' + t('byFirstName'),
    },
    {
      name: 'lastName',
      label: t('search') + ' ' + t('byLastName'),
    },
    {
      name: 'csvDate',
      label: '',
      type: 'date',
    },
  ])
  const [patiens, setPatiens] = useState<Map<string, Patient>>(new Map())

  useEffect(() => {
    if (!isLoading || !authService.get().clinics.length) {
      return
    }
    setIsLoading(true)
    const query = [...searcherQuery(searcher)]
    if (!authService.get().permissions.includes(Permission.viewAll)) {
      query.push(new QueryParam<PatientQuery>('clinics', authService.get().clinics))
    }
    patientService
      .getFilteredList(
        new Query({
          pager: { offset: page * itemsPerPage, limit: itemsPerPage },
          query,
          sort: [{ field: sort.field, desc: sort.desc }],
        }),
      )
      .subscribe((res) => {
        setIsLoading(false)
        setItems(res.items)
        setCount(res.count)
        const tmpMapPatiens = new Map<string, Patient>()
        res.items.forEach((i) => i.clinicID && tmpMapPatiens.set(i.id, i))
        setPatiens(tmpMapPatiens)
      })
  }, [isLoading])

  useEffect(() => {
    setIsLoading(true)
    setPager({
      page,
      count,
      handleChangePage: handlePaginationChange,
      rowsPerPage: itemsPerPage,
      handleChangeRowsPerPage,
    })
  }, [page, count, itemsPerPage])

  const handlePaginationChange = (event: unknown, value: number) => setPage(value)

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (Number.isNaN(event.target.value)) {
      setItemsPerPage(10)
      return
    }
    setItemsPerPage(Number.parseInt(event.target.value))
  }

  const fields: Field<Patient>[] = [
    {
      searchable: true,
      sortable: true,
      label: t('idBioarray'),
      name: 'bioarrayCode',
    },
    {
      searchable: true,
      label: t('sampleName'),
      name: 'clinicID',
      //renderFunc: (field, item) => clinics.get(item.clinicID)?.id || ''
      renderFunc: (field, item) => patiens.get(item.id)?.sampleName || '',
    },
    {
      searchable: true,
      label: t('idPatient'),
      name: 'idPatient',
      renderFunc: (field, item) => patiens.get(item.id)?.idPatient || '',
    },
    {
      searchable: true,
      sortable: true,
      label: t('firstName'),
      name: 'firstName',
    },
    {
      searchable: true,
      sortable: true,
      label: t('lastName'),
      name: 'lastName',
    },
    {
      sortable: true,
      label: t('dateOfBirth'),
      name: 'dob',
      renderFunc: (f, i) => i.dob ? new Date(i.dob).toLocaleDateString():''
    },
    {
      sortable: true,
      label: t('gender'),
      name: 'gender',
      renderFunc: (f, i) => userGenders()[i.gender],
    },
    {
      searchable: true,
      sortable: true,
      label: t('csvDate'),
      name: 'csvDate',
      renderFunc: (f, i) => i.csvDate ? new Date(i.csvDate).toLocaleDateString() : '',
    },
  ]

  const viewPatient = (patient: Patient) =>
    navigate(URL_PATIENT_SHOW.replace(':id', `${patient.id}`))

  const editPatient = (patient: Patient) => navigate(URL_PATIENT.replace(':id', `${patient.id}`))

  const deletePatient = (p: Patient) => patientService.delete(p.id).subscribe(() => setIsLoading(true))

  const actions: Actions<Patient> = {
    actionsColumn: t('Actions'),
    items: [
      {
        handler: viewPatient,
        icon: seeIcon,
        label: t('Show'),
      },
      {
        handler: editPatient,
        icon: editIcon,
        label: t('Edit'),
        hidden: () => !authService.userCan(Permission.editPatients),
      },
      {
        handler: deletePatient,
        icon: deleteIcon,
        label: t('Delete'),
        hidden: () => !authService.userCan(Permission.deletePatients),
      },
    ],
  }

  function isValidDate(dateStr: string) {
    console.log(!isNaN(Date.parse(dateStr)))
    return !isNaN(Date.parse(dateStr));
  }

  const search: Search<PatientQuery> = {
    searchValues: searcher,
    handleSearch: (svs: SearchValue<PatientQuery>[]) => {
      const result: SearchValue<PatientQuery>[] = []
      svs.forEach((s) => {
        if (s.type === 'date' && s.value) {
          result.push(Object.assign({ ...s }, { value: isValidDate(s.value) ? new Date(s.value).toISOString() : s.value }))
        } else {
          result.push(s)
        }
      })
      setSearcher(result)
      setIsLoading(true)
    },
  }

  const sortable: Sort<Patient> = {
    name: sort.field,
    direction: sort.desc ? 'desc' : 'asc',
    handleSort: (field) => {
      setSort({ field: field, desc: sort.field === field ? !sort.desc : true })
      setIsLoading(true)
    },
  }

  const createPatient = () => navigate(URL_PATIENT.replace(':id', 'create'))

  return (
    <>
      <Box justifyContent={'space-between'} mb={2}>
        {authService.userCan(Permission.editPatients) && (
          <Button
            className={classes.button}
            variant={'contained'}
            onClick={createPatient}
            style={{ float: 'right' }}>
            {t('create')}
          </Button>
        )}
      </Box>
      <AppTable
        styleHeader={{ color: COLOR_PATIENTS }}
        actions={actions}
        fields={fields}
        items={items}
        rowKeyField={'id'}
        pager={pager}
        search={search}
        sort={sortable}
      />
    </>
  )
}
