import React, { useState } from 'react'
import { Search, SearchValue } from './types'
import { Box, TextField } from '@material-ui/core'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { parseISO } from 'date-fns';


type SearchProps<Q> = {
  readonly search: Search<Q>
}

export function Searcher<Q extends { [key: string]: any }>(props: SearchProps<Q>) {
  const [values, setValues] = useState<SearchValue<Q>[]>(props.search.searchValues)

  const handleSubmit = () => props.search.handleSearch([...values])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    values.find((sv) => e.target.name == sv.name)!.value = e.target.value
    setValues([...values])
    handleSubmit()
  }

  const handleChangeDate = (name: String, value: Date) => {
      values.find((sv) => name == sv.name)!.value = value as unknown as string
      setValues([...values])
      handleSubmit()
  }

  const getDateIgnoreTimezone = (date: Date) =>
  date && date.getUTCHours() !== 0
    ? ((theDate) =>
        new Date(theDate.getTime() - theDate.getTimezoneOffset() * 60 * 1000))(
        new Date(date)
      )
    : date;

  return (
    <form onSubmit={handleSubmit}>
      <Box marginBottom="5px">
        <Box display="flex" flexWrap="wrap">
          {values.map((v) => (
            <Box mr={2} mb={2} width={v.width} key={v.name as string}>
              {v.type != "date" ? (
                <TextField
                  name={v.name as string}
                  label={v.label}
                  onChange={handleChange}
                  type={v.type || 'text'}
                  value={v.value || ''}
                  variant="outlined"
                  size="small"
                />
              ): 
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  style={{ width: '100%' }}
                  id="date"
                  label={v.label}
                  name={v.name as string}
                  inputVariant={'outlined'}
                  format="dd/MM/yyyy"
                  value={v.value || null}
                  placeholder={"dd/mm/aaaa"}
                  onChange={(a)=> a && handleChangeDate(v.name as string, getDateIgnoreTimezone(a))}
                  required={true}
                  size={'small'}
                  helperText={''}
                  error={false}
                />
              </MuiPickersUtilsProvider>
              }
            </Box>
          ))}
        </Box>
      </Box>
    </form>
  )
}

