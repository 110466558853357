import { COLOR_MATCHING, COLOR_PRIMARY } from '../../routes/color-constants'
import { AppTable, Field } from '../../components/table'
import React, { useEffect, useState } from 'react'
import { Actions, Pager, Search, SearchValue, Sort } from '../../components/table/types'
import downloadIcon from '../../assets/table-icons/download-icon.svg'
import { useTranslation } from 'react-i18next'
import { Query, QueryParam, QueryParamN, SortParam } from '../../common/api/Query'
import { navigate } from '@reach/router'
import { URL_MATCHING_NEW } from '../../routes/routes-constants'
import { getMatchingContainer } from '../../container/matching-modules'
import { MatchingService } from '../../modules/matchings/services/MatchingService'
import { MATCHING_SERVICE_KEY } from '../../modules/matchings'
import { Matching, MatchingQuery } from '../../modules/matchings/models/Matching'
import { Patient, PatientQuery } from '../../modules/patients/models/Patient'
import { PatientService } from '../../modules/patients/services/PatientService'
import { PATIENT_SERVICE_KEY } from '../../modules/patients'
import { getPatientContainer } from '../../container/patient-module'
import { matchingTypes } from '../../modules/matchings/enums/MatchingType'
import { Box, Button } from '@material-ui/core'
import { useStyles } from './Matching.styles'
import { getAuthContainer } from '../../container/auth-modules'
import { AuthService } from '../../modules/auth/services/AuthService'
import { AUTH_SERVICE_KEY } from '../../modules/auth'
import { Permission } from '../../common/enums/Permissions'

const matchingService = getMatchingContainer().get<MatchingService>(MATCHING_SERVICE_KEY)
const patientService = getPatientContainer().get<PatientService>(PATIENT_SERVICE_KEY)
const authService = getAuthContainer().get<AuthService>(AUTH_SERVICE_KEY)

const searcherQuery = (
  svs: SearchValue<MatchingQuery>[],
): QueryParam<MatchingQuery>[] | QueryParamN<MatchingQuery>[] =>
  svs.filter((sv) => sv.value).map((sv) => ({ name: sv.name, value: sv.value as string }))

export const Table = () => {
  const classes = useStyles({ color: COLOR_PRIMARY })
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [pager, setPager] = useState<Pager>()
  const [page, setPage] = useState<number>(0)
  const [count, setCount] = useState<number>(0)
  const [itemsPerPage, setItemsPerPage] = useState<number>(10)
  const [items, setItems] = useState<Matching[]>([])

  const [sort, setSort] = useState<SortParam<Matching>>({
    field: 'date',
    desc: true,
  })

  const [searcher, setSearcher] = useState<SearchValue<MatchingQuery>[]>([
    {
      name: 'clinicCode',
      label: t('search') + ' ' + t('bySampleName'),
    },
    {
      name: 'idPatient',
      label: t('search') + ' ' + t('idPatient'),
    },
    {
      name: 'firstName',
      label: t('search') + ' ' + t('byPatientFirstName'),
    },
    {
      name: 'lastName',
      label: t('search') + ' ' + t('byPatientLastName'),
    },
    {
      name: 'date',
      label: '',
      type: 'date',
    },
  ])
  const [patients, setPatients] = useState<Map<string, Patient>>(new Map())

  useEffect(() => {
    if (!isLoading || !authService.get().clinics.length) {
      return
    }

    const query = [...searcherQuery(searcher)]
    if (!authService.get().permissions.includes(Permission.viewAll)) {
      query.push(new QueryParam<MatchingQuery>('clinics', authService.get().clinics))
    }
    matchingService
      .getFilteredList(
        new Query({
          pager: { offset: page * itemsPerPage, limit: itemsPerPage },
          query,
          sort: [{ field: sort.field, desc: sort.desc }],
        }),
      )
      .subscribe((res) => {
        setIsLoading(false)
        setItems(res.items)
        setCount(res.count)
      })
  }, [isLoading])

  useEffect(() => {
    if (!items.length) {
      return
    }
    patientService
      .getFilteredList(
        new Query({
          pager: { offset: 0, limit: itemsPerPage * 2 },
          query: [
            new QueryParam<PatientQuery>('ids', [
              ...items.map((i) => i.mainPatientID),
              ...items.map((i) => i.donorID),
            ]),
          ],
          sort: [{ field: 'firstName' }],
        }),
      )
      .subscribe((res) => {
        const tmpMap = new Map<string, Patient>()
        const mapAux = new Map<string, Patient>()
        res.items.forEach((i) => {
          tmpMap.set(i.id, i)
          mapAux.set(i.idPatient,i)
        })
        setPatients(tmpMap)
      })
  }, [items])

  useEffect(() => {
    setIsLoading(true)
    setPager({
      page,
      count,
      handleChangePage: handlePaginationChange,
      rowsPerPage: itemsPerPage,
      handleChangeRowsPerPage,
    })
  }, [page, count, itemsPerPage])

  const handlePaginationChange = (event: unknown, value: number) => setPage(value)

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (Number.isNaN(event.target.value)) {
      setItemsPerPage(10)
      return
    }
    setItemsPerPage(Number.parseInt(event.target.value))
  }

  const handleMatchingResult = (field: Field<Matching>, item: Matching) => {
      if (item.resultNotAvailable){
        return t('notAvailable')
      }
      return item.resultNotValid ? t('notValid') : t('matching' + item.result.toString())
  }

  const fields: Field<Matching>[] = [
 /*   {
      searchable: true,
      label: t('idPatient'),
      name: 'idPatient',
      renderFunc: (field, item) => patients.get(item.mainPatientID)?.idPatient || '',
    },*/
    {
      searchable: true,
      label: t('patient'),
      name: 'mainPatientID',
      renderFunc: (field, item) => patients.get(item.mainPatientID)?.fullName || '',
    },
    {
      label: t('donor'),
      name: 'donorID',
      renderFunc: (field, item) => patients.get(item.donorID)?.fullName || '',
    },
    {
      sortable: true,
      label: t('matchingType'),
      name: 'type',
      renderFunc: (field, item) => matchingTypes()[item.type],
    },
    {
      sortable: true,
      label: t('result'),
      name: 'result',
      renderFunc: (field, item) => handleMatchingResult(field, item),
    },
    {
      sortable: true,
      label: t('date'),
      name: 'date',
      renderFunc: (field, item) => new Date(item.date).toLocaleString().substring(0, new Date(item.date).toLocaleString().length - 3) + ' h',
    },
    {
      sortable: true,
      label: t('diseasesBlinded'),
      name: 'hideDiseases',
      renderFunc: (field, item) => item.resultNotValid ? "" : t(String(item.hideDiseases)),
    },
  ]

  function isValidDate(dateStr: string) {
    return !isNaN(Date.parse(dateStr));
  }

  const search: Search<any> = {
    searchValues: searcher,
    handleSearch: (svs: SearchValue<MatchingQuery>[]) => {
      const result: SearchValue<MatchingQuery>[] = []
      svs.forEach((s) => {
        if (s.type === 'date' && s.value) {
          result.push(Object.assign({ ...s }, { value: isValidDate(s.value) ? new Date(s.value).toISOString() : s.value }))
        } else {
          result.push(s)
        }
      })
      setSearcher(result)
      setIsLoading(true)
    },
  }

  const downloadMatching = (m: Matching) => {
    window.open(process.env.REACT_APP_BACKEND_URL + '/matchings/' + m.id + '/pdf', '_blank')
  }

  //const deleteMatching = (m: Matching) => matchingService.delete(m.id).subscribe(()=>setIsLoading(true))


  const actions: Actions<Matching> = {
    actionsColumn: t('Actions'),
    items: [
      {
        handler: downloadMatching,
        icon: downloadIcon,
        label: t('Download'),
        hidden: (item) => item.resultNotValid
      },
      /*
      {
        handler: deleteMatching,
        icon: deleteIcon,
        label: t('Delete'),
        hidden: () => !authService.userCan(Permission.deleteMatchings)
      },*/
    ],
  }

  const sortable: Sort<Matching> = {
    name: sort.field,
    direction: sort.desc ? 'desc' : 'asc',
    handleSort: (field) => {
      setSort({ field: field, desc: sort.field === field ? !sort.desc : true })
      setIsLoading(true)
    },
  }

  const onNew = () => navigate(URL_MATCHING_NEW)

  return (
    <>
      <Box justifyContent={'space-between'} mb={2}>
        <Button
          className={classes.button}
          variant={'contained'}
          style={{ float: 'right' }}
          onClick={onNew}>
          {t('create')}
        </Button>
      </Box>
      <AppTable
        styleHeader={{ color: COLOR_MATCHING }}
        actions={actions}
        fields={fields}
        items={items}
        rowKeyField={'id'}
        pager={pager}
        search={search}
        sort={sortable}
      />
    </>
  )
}
