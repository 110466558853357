import { RouteProps } from '../../routes/AppRouter'
import { useTranslation } from 'react-i18next'
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core'
import { useStyles } from './Matching.styles'
import { Title } from '../../components/common/Title'
import { Clinic, ClinicQuery } from '../../modules/clinics/models/Clinic'
import { MatchingType, matchingTypes } from '../../modules/matchings/enums/MatchingType'
import React, { useEffect, useState } from 'react'
import { useForm } from '../../common/utils/form-generation/useForm'
import { getPatientContainer } from '../../container/patient-module'
import { PatientService } from '../../modules/patients/services/PatientService'
import { PATIENT_SERVICE_KEY } from '../../modules/patients'
import { getClinicContainer } from '../../container/clinic-modules'
import { ClinicService } from '../../modules/clinics/services/ClinicService'
import { CLINIC_SERVICE_KEY } from '../../modules/clinics'
import { BoolQueryParam, Query, QueryParam } from '../../common/api/Query'
import { Patient, PatientQuery } from '../../modules/patients/models/Patient'
import { Autocomplete } from '@material-ui/lab'
import { MatchingDTO } from '../../modules/matchings/models/Matching'
import { getMatchingContainer } from '../../container/matching-modules'
import { MatchingService } from '../../modules/matchings/services/MatchingService'
import { MATCHING_SERVICE_KEY } from '../../modules/matchings'
import { navigate } from '@reach/router'
import { URL_MATCHINGS } from '../../routes/routes-constants'
import { v4 as uuidv4 } from 'uuid'
import { EntityMultipleSelect } from '../common'
import { forkJoin } from 'rxjs'
import { getAuthContainer } from '../../container/auth-modules'
import { IAuthService } from '../../modules/auth/services/AuthService'
import { AUTH_SERVICE_KEY } from '../../modules/auth'
import { GenderType } from "../../modules/users/enums/GenderType";
import { Permission } from '../../common/enums/Permissions'

const clinicService = getClinicContainer().get<ClinicService>(CLINIC_SERVICE_KEY)
const patientService = getPatientContainer().get<PatientService>(PATIENT_SERVICE_KEY)
const matchingService = getMatchingContainer().get<MatchingService>(MATCHING_SERVICE_KEY)
const authService = getAuthContainer().get<IAuthService>(AUTH_SERVICE_KEY)

export const Form = (props: RouteProps) => {
  const { t } = useTranslation()
  const classes = useStyles({ color: props.color })

  const [clinics, setClinics] = useState<Clinic[]>([])
  const [patients, setPatients] = useState<Patient[]>([])
  const [donorIDs, setDonorIDs] = useState<string[]>([])
  const [selectedClinic, setSelectedClinic] = useState<Clinic>()
  const [types, setTypes] = useState<string[]>([])
  const [selectedGender, setSelectedGender] = useState<GenderType>()

  const { handleSubmit, handleChange, data, setData, errors } = useForm<MatchingDTO>({
    validations: {
      type: {
        required: {
          value: true,
          message: t('selectOneTypeOfMatching'),
        },
      },
      mainPatientID: {
        required: {
          value: true,
          message: t('selectAtLeastOnePatient'),
        },
      },
    },

    onSubmit: () => {
      forkJoin(
        donorIDs.map((d) =>
          matchingService.add({ ...data, id: uuidv4(), donorID: d, date: new Date() })
        )
      ).subscribe(() => { goToMatchings()
      })
    },
    initialValues: {
      hideDiseases: false,
    },
  })

  const goToMatchings = () => navigate(URL_MATCHINGS)

  const handleValueClinic = (clinic: Clinic) => setSelectedClinic(clinic)

  useEffect(() => {
    if (!authService.get().clinics.length) {
      return
    }
    const query: QueryParam<ClinicQuery>[] = []
    if (!authService.get().permissions.includes(Permission.viewAll)) {
      query.push(new QueryParam<ClinicQuery>('ids', authService.get().clinics))
    }
    clinicService
      .getFilteredList(
        new Query({
          pager: { offset: 0, limit: -1 },
          query,
          sort: [{ field: 'name' }],
        })
      )
      .subscribe((res) => {
        setClinics(res.items)
      })
  }, [])

  useEffect(() => {
    if (!selectedClinic?.id) {
      return
    }

    if (selectedClinic.testType) {
      setTypes(Object.keys(matchingTypes()))
    } else {
      setTypes(
        Object.keys(matchingTypes()).filter(
          (m) => +m !== MatchingType.HighFrecuency && +m !== MatchingType.HighFrecuencyXlinked
        )
      )
    }

    patientService
      .getFilteredList(
        new Query({
          pager: { offset: 0, limit: -1 },
          query: [
            //new QueryParam<PatientQuery>('haveTest', new BoolQueryParam(true)),
            new QueryParam<PatientQuery>('clinics', [selectedClinic.id]),
          ],
          sort: [{ field: 'firstName' }],
        })
      )
      .subscribe((res) => {
        setPatients(res.items)
      })
  }, [selectedClinic])

  return (
    <div className={classes.root}>
      <Title title={t('newMatching')} color={props.color} />
      <form onSubmit={handleSubmit} autoComplete={'off'}>
        <Grid className={classes.container} container spacing={1}>
          <Grid className={classes.container} item xs={12} justify={'flex-start'}>
            <Typography className={classes.stepTitle} align={'left'} gutterBottom variant={'h3'}>
              {t('step') + ' 01'}
            </Typography>
            <Box className={classes.stepBox}>
              <Typography
                className={classes.stepSubtitle}
                align={'left'}
                gutterBottom
                variant={'h3'}>
                {t('selectClinic')}
              </Typography>
              <Autocomplete
                disableClearable={true}
                className={classes.select}
                inputValue={selectedClinic && selectedClinic.name}
                getOptionSelected={(option, value) => value && option.name === value.name}
                getOptionLabel={(option) => option.name}
                options={clinics}
                noOptionsText={t('noClinics')}
                size={'small'}
                onChange={(_, value) => handleValueClinic(value as Clinic)}
                renderInput={(params) => <TextField {...params} variant={'outlined'} />}
              />
            </Box>
          </Grid>
          <Grid className={classes.container} item xs={12} justify={'flex-start'}>
            <Typography className={classes.stepTitle} align={'left'} gutterBottom variant={'h3'}>
              {t('step') + ' 02'}
            </Typography>
            <Box className={classes.stepBox}>
              <Typography
                className={classes.stepSubtitle}
                align={'left'}
                gutterBottom
                variant={'h3'}>
                {t('selectMatchingType')}
              </Typography>
              <Select
                fullWidth
                style={{ textAlign: 'left' }}
                variant={'outlined'}
                className={classes.select}
                value={data.type}
                onChange={(event) => handleChange('type', event.target.value as MatchingType)}>
                {types.filter((k) => parseInt(k) !== MatchingType.Comprehensive)
                  .map((k) => (
                    <MenuItem key={k} value={parseInt(k)}>
                      {t(matchingTypes()[k as unknown as MatchingType])}
                    </MenuItem>
                  ))}
              </Select>
            </Box>
          </Grid>
          <Grid className={classes.container} item xs={6} justify={'flex-start'}>
            <Typography className={classes.stepTitle} align={'left'} gutterBottom variant={'h3'}>
              {t('step') + ' 03'}
            </Typography>
            <Box className={classes.stepBox}>
              <Typography
                className={classes.stepSubtitle}
                align={'left'}
                gutterBottom
                variant={'h3'}>
                {t('mainPatient')}
              </Typography>
              <Autocomplete
                disableClearable={true}
                fullWidth
                className={classes.select}
                inputValue={data.mainPatientID}
                getOptionSelected={(option, value) => value && option.firstName === value.firstName}
                getOptionLabel={(option) => option.firstName + ' ' + option.lastName + ' - ' + option.idPatient}
                options={patients}
                onChange={(_, value) => {
                  const p = (value as Patient)
                  handleChange('mainPatientID', p.id)
                  setSelectedGender(p.gender)
                }}
                renderInput={(params) => <TextField {...params} variant={'outlined'} />}
                noOptionsText={t('noPatients')}
              />
            </Box>
          </Grid>
          <Grid className={classes.container} item xs={6} justify={'flex-start'}>
            <Typography className={classes.stepTitle} align={'left'} gutterBottom variant={'h3'}>
              {t('step') + ' 04'}
            </Typography>
            <Box className={classes.stepBox}>
              <Typography
                className={classes.stepSubtitle}
                align={'left'}
                gutterBottom
                variant={'h3'}>
                {t('donor')}
              </Typography>
              <EntityMultipleSelect
                className={classes.select}
                name={(option) => option.firstName + ' ' + option.lastName + ' - ' + option.idPatient}
                values={donorIDs}
                options={data.mainPatientID ? patients.filter((p) => p.gender !== selectedGender) : []}
                onChange={(values) => setDonorIDs(values.map((v) => v.id))}
                pk={'id'}
              />
            </Box>
          </Grid>
          <Grid className={classes.container} item xs={6} style={{ textAlign: 'left' }}>
            <Typography className={classes.stepTitle} align={'left'} gutterBottom variant={'h3'}>
              {t('step') + ' 05'}
            </Typography>
            <Box className={classes.stepBox}>
              <Box style={{ background: 'white' }} pl={1}>
                <FormControlLabel
                  control={
                    <Checkbox
                      value={data.hideDiseases}
                      onChange={() => handleChange('hideDiseases', !data.hideDiseases)}
                    />
                  }
                  label={t('hideDiseases')}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Grid className={classes.container} container spacing={1}>
          <Grid item xs={3}>
            <Button
              fullWidth
              type={'submit'}
              className={classes.button}
              onClick={() => handleSubmit}
              variant={'contained'}>
              {t('doMatching')}
            </Button>
          </Grid>
          <Grid item xs={2}>
            <Button
              fullWidth
              className={classes.buttonSecondary}
              onClick={goToMatchings}
              variant={'contained'}>
              {t('cancel')}
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  )
}
