import React from 'react'
import { Box, Icon, IconButton, TableBody, TableCell, TableRow } from '@material-ui/core'
import { Actions, Field } from './types'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import { GenericTooltip } from '../generic-tooltip'

export type BodyProps<T> = {
  readonly fields: Field<T>[]
  readonly items: T[]
  readonly rowKeyField: keyof T
  readonly actions?: Actions<T>
}

export function Body<T extends { [key: string]: any }>(props: BodyProps<T>) {
  const { t } = useTranslation()

  const useStyles = makeStyles((_) => ({
    body: {
      fontSize: '14px',
      fontFamily: 'poppins',
      borderBottom: '1px solid #D1D1D1',
      padding: '0 auto 0 auto',
    },
    icon: {
      verticalAlign: 'middle',
      cursor: 'pointer',
      paddingTop: '0',
      paddingBottom: '0',
    },
  }))

  const classes = useStyles()

  const rows = props.items.map((item) => (
    <TableRow key={item[props.rowKeyField]}>
      {Object.values(props.fields).map((field) => (
        <TableCell key={field.name as string}>
          {field.renderFunc
            ? field.renderFunc(field, item)
            : item[field.name] && item[field.name].toString()}
        </TableCell>
      ))}

      {props.actions && props.actions.items?.length > 0 && (
        <TableCell key="actions-table">
          {/** @ts-ignore */}
          <Box display="flex" justifyContent="space-between">
            {props.actions.items.map((a) => {
              if (a.hidden && a.hidden(item)) {
                return <></>
              }
              return (
                <GenericTooltip
                  values={[t(a.label || a.icon)]}
                  icon={
                    <img
                      src={a.icon}
                      className={classes.icon}
                      key={item[props.rowKeyField]}
                      onClick={() => a.handler(item)}
                    />
                  }
                  noUseFab={true}
                />
              )
            })}
          </Box>
        </TableCell>
      )}
    </TableRow>
  ))

  return <TableBody>{rows}</TableBody>
}
